import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("turbo:before-stream-render", this._beforeStreamRender)
  }

  disconnect() {
    window.removeEventListener("turbo:before-stream-render", this._beforeStreamRender)
  }

  _beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render
    const scrollElement = document.getElementById("main-scroll")

    event.detail.render = async function(streamElement) {
      // Find streamElement targets
      let targetElements = []
      if (streamElement.target) {
        const element = document.getElementById(streamElement.target)
        targetElements = (element === null) ? [] : [element]
      } else if (streamElement.targets) {
        const elements = document.querySelectorAll(streamElement.targets)
        targetElements = (elements.length === 0) ? [] : Array.prototype.slice.call(elements)
      }

      // Check if any target is inside the scroll area
      const scrollElementContainsTarget = targetElements.some((el) => (
        scrollElement.contains(el)
      ))

      // Remember the current scroll position
      const scrollBottomOffset = scrollElement.scrollHeight -
        scrollElement.getBoundingClientRect().height -
        scrollElement.scrollTop

      // Process the streamElement
      await fallbackToDefaultActions(streamElement)

      // If the previous scroll position was near the bottom, scroll to bottom
      if (scrollElementContainsTarget && scrollBottomOffset < 10) {
        scrollElement.scrollTop = scrollElement.scrollHeight
      }
    }
  }
}
