import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitBtn", "input"]

  connect() {
    this.toggleSubmitBtn()
  }

  submit() {
    this.submitBtnTarget.click()
  }

  toggleSubmitBtn() {
    if (this.hasInputTarget) {
      this.submitBtnTarget.disabled = this.inputTarget.value.length <= 0
    }
  }
}
