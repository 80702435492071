import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["counter", "checkbox", "checkboxAll", "checkboxTmp"]

  connect() {
    this.labelBase = this.data.get("label")
    this.updateCheckboxAll()
    this.updateCounter()
  }

  updateCounter() {
    const allCount = this.checkboxTargets.length
    const checkedCount = this.checkboxTargets.filter(el => el.checked).length

    const counterLabel = checkedCount === allCount ? this.labelBase : checkedCount
    this.counterTarget.innerHTML = counterLabel
  }

  updateCheckboxAll() {
    const allCount = this.checkboxTmpTargets.length
    const checkedCount = this.checkboxTmpTargets.filter(el => el.checked).length

    if (checkedCount === 0) {
      this.checkboxAllTarget.checked = false
      this.checkboxAllTarget.indeterminate = false
    } else if (checkedCount === allCount) {
      this.checkboxAllTarget.checked = true
      this.checkboxAllTarget.indeterminate = false
    } else {
      this.checkboxAllTarget.checked = false
      this.checkboxAllTarget.indeterminate = true
    }
  }

  checkAll(event) {
    this.checkboxTmpTargets.forEach((element) => {
      element.checked = event.target.checked
    })
  }

  confirm() {
    this.checkboxTmpTargets.forEach((tmpElement) => {
      const element = this.checkboxTargets.find(el => tmpElement.value === el.value)
      element.checked = tmpElement.checked
    })

    this.updateCounter()
  }

  reset() {
    this.checkboxTmpTargets.forEach((tmpElement) => {
      const element = this.checkboxTargets.find(el => tmpElement.value === el.value)
      tmpElement.checked = element.checked
    })

    this.updateCheckboxAll()
  }
}
